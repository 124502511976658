import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    Hosted on IPFS
    <br />
    🦇🔊
  </footer>
)

export default Footer
