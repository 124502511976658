import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import EmailIcon from '@material-ui/icons/Email'

import { SvgIcon, Box } from '@material-ui/core'

import { ReactComponent as OlympusDaoSVG } from '../../assets/logos/olympusdao.svg'
import { ReactComponent as GnosisSVG } from '../../assets/logos/safe.svg'
import { ReactComponent as ZapperSVG } from '../../assets/logos/zapper.svg'
import { ReactComponent as Ethereum } from '../../assets/logos/ethereum.svg'
import { ReactComponent as Artemis } from '../../assets/logos/artemis.svg'

import { about } from '../../portfolio'

const About = () => {
  const { name, role, description, resume, social } = about
  const intro = `Hi, I'm`
  return (
    <div className='about center'>
      <h5 style={{ textAlign: 'center' }} className='about__role'>
        Work done for
      </h5>

      <Box
        display='flex'
        flexWrap='wrap'
        flexDirection='row'
        justifyContent='center'
        mt='25px'
      >
        <SvgIcon
          style={{
            fontSize: '150px',
            height: 'fit-content',
            marginTop: '10px',
          }}
          component={OlympusDaoSVG}
          viewBox='0 0 129 24'
        />
        <Box width='20px' />
        <SvgIcon
          style={{
            fontSize: '150px',
            height: 'fit-content',
            marginTop: '10px',
          }}
          component={GnosisSVG}
          viewBox='0 0 1400 265'
          fill='#fff'
        />
        <Box width='20px' />
        <SvgIcon
          style={{
            fontSize: '150px',
            height: 'fit-content',
            marginTop: '10px',
          }}
          component={ZapperSVG}
          viewBox='0 0 1000 280'
          fill='#fff'
        />
        <SvgIcon
          style={{
            fontSize: '150px',
            height: 'fit-content',
            marginTop: '10px',
          }}
          component={Ethereum}
          viewBox='0 0 1080 280'
          fill='#fff'
        />
        <Box width='20px' />
        <SvgIcon
          style={{
            fontSize: '108px',
            height: 'fit-content',
            marginTop: '10px',
          }}
          component={Artemis}
          viewBox='270 180 670 240'
          fill='#fff'
        />
      </Box>
    </div>
  )
}

export default About
